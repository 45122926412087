
<template>
  <section class="rotation">
    <div class="itemContainer">
      <div :class="{'item-border':isBorder}">
           <swiper ref="mySwiper" :slides-per-view="5" :options="swiperOptions">
              <swiper-slide class="item-device"  v-for="(item, index) in examples" :key="index">
                  <div>
                      <div class="dev-img" @click="scaleImg(item.img)">
                          <img :src="item.url" style="width:100%;height:auto"
                      alt="">
                      </div>
                  </div>
              </swiper-slide>
              <div class="swiper-button-prev" ref="prevBtn"   slot="button-prev">
                 <i class="el-icon-arrow-left"></i>
              </div>
              <div class="swiper-button-next"   ref="nextBtn"  slot="button-next">
                <i class="el-icon-arrow-right button2"></i>
              </div>
         </swiper>
         <p v-if="pTitle" class="rotation-desc">{{pTitle}}</p>
      </div>
        
        
<!-- 
      <div
        class="carouselContainer"
        v-for="(item,index) in examples"
        :key="item.text"
        :style="{left:index*374 + 'rem'}"
        :ref="'carouselContainer'+index"
      >
        <img
          :src="item.url"
          alt=""
        >
      </div> -->

      <div class="btnContainer">
            <span class="button button1"  @click="leftClick"> <i class="el-icon-arrow-left"></i></span>
            <span class="button button2" @click="rightClick"> <i class="el-icon-arrow-right"></i></span>
     </div>
        <!--       
      <img src="@/assets/social/per.png" class="button1" @click="leftClick" alt="">
      <img src="@/assets/social/next.png" class="button2" @click="rightClick" alt=""> -->
    


    </div>

  </section>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'ArrowCur',
  components: {swiper, swiperSlide},
  props:{
    examples:{
      type:Array,
      default:()=>{
        return []
      },
    },
    pTitle:{
      type:String,
      default:''
    },
    isBorder:{
     type:Boolean,
     default:false
    }
  },
  data() {
    return {
       swiperOptions: {
           slidesPerView: 3,
           paginationClickable: true,
           spaceBetween: 30,
           loop: true,
           navigation: {
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
             },
                // slidesOffsetBefore: 10,
                // slidesOffsetAfter: 10,
                // slidesPerView: 3, // 可见数量
                // freeMode: true,
                // spaceBetween: 30,
                // // history: 'love',
                // // 设置点击箭头
                // navigation: {
                //     nextEl: '.swiper-button-prev',
                //     prevEl: '.swiper-button-next',
                // },
                // // autoplay:{
                // //   delay:3500,
                // //   autoplayDisableOnInteraction:false
                // // },
                // centeredSlides: true,
                // loop: true,
                  
        },
    }
  },
  created() {

  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
  },
  methods: {
     prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    leftClick() {
        this.$refs.nextBtn.click();
      // for (let i = 1; i < this.examples.length; i++) {
      //   var computedStyle = document.defaultView.getComputedStyle(this.$refs['carouselContainer' + i][0], null)
      //   this.$refs['carouselContainer' + i][0].style.left = parseInt(computedStyle.left) - 374 + "rem"
      // }
      // setTimeout(() => {
      //   let temp = this.examples.shift();
      //   this.examples.push(temp)
      // }, 1000)

    },
    rightClick() {
       this.$refs.prevBtn.click();
      // for (let i = 0; i < this.examples.length - 1; i++) {
      //   var computedStyle = document.defaultView.getComputedStyle(this.$refs['carouselContainer' + i][0], null)
      //   this.$refs['carouselContainer' + i][0].style.left = parseInt(computedStyle.left) + 374 + "rem"
      // }
      // setTimeout(() => {
      //   let temp = this.examples.pop();
      //   this.examples.unshift(temp)
      // }, 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
.rotation {
  width:14.32rem;
  margin:0 auto;
  box-sizing: border-box;
  padding:0 0.50rem 0.40rem 0.50rem;
  margin-bottom:40px;
  .itemContainer {
    width: calc(14.32rem - 130px); 
    // overflow: hidden;
    // height: 1.97rem;
    position: relative;
    .item-border{
      border: 1px solid rgba(191,199,209,0.50);
      }
    .rotation-desc{
      margin:24px 0;
      font-size:0.18rem;
    }
    .swiper-container{
      height:1.97rem;
    }
      /deep/ .swiper-button-prev, /deep/ .swiper-button-next{
          visibility: hidden;
          width: 0.51rem;
          height: 0.51rem;
          text-align: center;
          line-height: 0.51rem;
          background: #FFFFFF;
          border-radius:50%;
          position: absolute;
          img{
            width: 100%;
            height:100%
          }
          
        }
         /deep/ .swiper-button-prev{
               left:-0.25rem;
         }

           /deep/ .swiper-button-next{
             right: -0.25rem;
         }
       
  }
  .carouselContainer {
    position: absolute;
    transition: all 1s !important;
    width: 3.51rem;
    height: 1.97rem;
    background: #FFFFFF;
    border: 0.01rem solid #FFFFFF;
    margin-right:0.24rem;
    img {
         width: 3.51rem;
    }
  }
  .btnContainer {
    width:100%;
    color: #000;
    position: absolute;
    // top: 400rem;
    z-index: 1;
    // z-index: 999;
    cursor: pointer;
      width: calc(14.32rem - 85px); 
      .button{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 0.51rem;
        height: 0.51rem;
        background: #136EFF;
        border-radius: 50%;
        i{
          font-size:0.26rem;
          color:#fff;
        }
        }
      .button1{
        z-index: 10;
        left: -0.30rem;
        top: -1.32rem;
      }
      .button2{
        top: -1.32rem;
        right: 0.10rem;
        z-index: 20;
      }
  }
}
/* fade */
.fade-enter-active {
  transform: scale(1.3);
  box-shadow: 0 0 0.10rem #ffffff;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter {
  transform: scale(1);
}
.fade-leave-active {
  transform: scale(0.6);
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-0.30rem);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(0.30rem);
}
</style>
