<template>
    <div class="page-box">
        <div>
            <Nav></Nav>
            <HeaderStyle :title="title" :describe="describe" :backgroundUrl="backgroundUrl"></HeaderStyle>
        </div>
        <div class="person-Train">
            <Title :content="content"></Title>
            <div class="train-dec">
                枫调理顺网络培训学院针构建了集“电子学习+面授训练+案例分享+专业文库+委托培训+学习运营”多种模式为一体的综合培训解决方案和完善的课程体系，全面、科学、系统地帮助行业或区域提高基层人员处置能力与解决问题的水平，进而提升基层社会治理的总体能力与水平。
            </div>
        </div>
        <div class="com-bg">
            <div class="section part-1">
                <div class="htitle">
                    基础社会治理人才培养方法
                </div>
                <div class="img-box">
                    <img src="@/assets/person/person1.png" alt="">
                </div>
            </div>
            <div class="section part-2">
                <div class="htitle htitle-spe">调解员课程体系</div>
                <!-- <div class="img-box">
                    <img src="@/assets/person/person2.png" alt="">
                </div> -->
                <div class="content-box">
                    <div class="content-box-1">
                      <div class="content-title">专业行业调解（80%电子化）</div>
                      <div class="content-items">
                        <div class="content-item" v-for="(item,index) in indMediations" :key="index">
                          <div class="content-item-info"><p v-for="(item1,index1) in item.matters" :key="index1">{{item1}}</p></div>
                          <div class="ke-name">{{item.matterType}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="content-box-2">
                      <div class="content-title">调解能力核心课程（40%电子化）</div>
                      <div class="content-items">
                          <div class="core-ke" v-for="(item,index) in abilitys" :key="index">
                            {{item}}
                          </div>
                      </div>
                    </div>
                    <div class="content-box-3">
                      <div v-for="(item,index) in base" :key="index" class="content-l-r-box">
                          <div class="neirong-box">
                            <div class="neirong" v-for="(item1,index) in item.partContent" :key="index">
                              <p class="neirong-p" >
                                {{item1.onep}}
                              </p>
                              <p class="neirong-p" >
                                {{item1.twop}}
                              </p>
                            </div>
                          </div>
                          <div class="content-title">基础课程（100%电子化）</div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="section part-3">
                <div class="htitle">网格员课程体系</div>
                <!-- <div class="img-box">
                    <img src="@/assets/person/person3.png" alt="">
                </div> -->
                 <div class="content-box">
                      <div class="content-title content-title-bg">八大员课程</div>
                   <div class="content-box-3">
                      <div class="content-l-r-box content-l-r-box-size">
                          <div class="neirong-box neirong-box-bg no-p">
                            <ul class="neirong-box-1">
                              <li v-for="(item,index) in wangge" :key="index">{{item}}</li>
                            </ul>
                          </div>
                      </div>
                       <div class="content-l-r-box content-l-r-box-size">
                          <div class="neirong-box neirong-box-bg">
                            <ul class="neirong-box-2">
                              <li v-for="(item,index) in wangge2" :key="index">{{item}}</li>
                            </ul>
                          </div>
                      </div>
                    </div>
                    <!-- 核心能力课程 -->
                   <div class="content-title content-title-bg">核心能力课程</div>
                   <div class="core-bg">
                      <div v-for="(item,index) in ablityCore" :key="index" class="core-item" :class="{'core-item-spe':(!item.content.length)}">
                        <p class="core-title">{{item.title}}</p>
                        <div class="core-info" v-if="item.content.length">
                          <div v-for="(item1,index1) in item.content" :key="index1">{{item1}}</div>
                        </div>
                      </div>
                   </div>

                 </div>
            </div>
            <div class="section">
                <div class="htitle">课题研究-网格矛盾调解工作法（中央政法委）</div>
               <Rotation :examples="keti"></Rotation>
            </div>
            <div class="section">
                <div class="htitle-p">课程培训</div>
                 <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="1">直播授课</el-menu-item>
                    <el-menu-item index="2">面授课程</el-menu-item>
                    <el-menu-item index="3">电子学习</el-menu-item>
                 </el-menu>
                 <div class="ration-wrap" v-if="activeIndex=='1'">
                     <Rotation v-if="activeIndex=='1'" :examples="tab1" :pTitle="pTitle" :isBorder="isBorder"></Rotation>
                 </div>
                  <div class="ration-wrap" v-if="activeIndex=='2'">
                     <Rotation v-if="activeIndex=='2'" :examples="tab2" :pTitle="pTitle" :isBorder="isBorder"></Rotation>
                 </div>
                  <div class="ration-wrap"  v-if="activeIndex=='3'">
                     <Rotation v-if="activeIndex=='3'" :examples="tab3" :pTitle="pTitle" :isBorder="isBorder"></Rotation>
                 </div>
            </div>
           <div class="section">
                <div class="htitle">合作伙伴</div>
                <div style="height:2.40rem;position:relative;">
                    <swiper ref="mySwiper" :slides-per-view="3" :options="swiperOptions">
                    <swiper-slide class="item-device" style="width:20%"  v-for="(item, index) in huobans" :key="index">
                        <div class="device">
                            <div class="dev-img">
                                <img :src="item.imgurl" width="50%"
                            alt="">
                            </div>
                        </div>
                    </swiper-slide>
                    </swiper>
                    <div class="swiper-pagination"></div><!--分页器。如果放置在swiper外面，需要自定义样式。-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppFooter from '@/components/layer/AppFooter'
import SerAsk from '@/components/layer/SerAsk'
import HeaderStyle from '@/components/commom/HeaderStyle'
import Rotation from './Rotation';
import Nav from "@/components/layer/HeaderNav";
import Title from "@/components/layer/OneTitle.vue";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
    name:'PerSonTrain',
  components: {
    AppFooter,
    SerAsk,
    HeaderStyle,
    Nav,
    Title,
    Rotation,
    swiper, 
    swiperSlide
  },
  data(){
      return {
        activeIndex:"2",
        title:"人才培养",
        backgroundUrl:"backgroundUrl",
        describe:"系统地提高社会治理体系中基层工作人员解决问题的能力，进而提升基层社会治理的总体能力与水平。",
        content: {
            name: "关于人才培养",
            egName: "ABOUT PERSONNEL TRAINNING"
        },
        pTitle:"石景山老街坊调委会培训",
        isBorder:true,
        baseCources:[
          "党建课程","理论课程","政策课程","民法典","社区志愿","心理课程"
        ],
        base:[
          {
            partContent:[{
               onep:'调解理论框架',
                twop:'国内外实践沿革'
            },
            {
            onep:'职业规划',
            twop:'职业道德'
          },
            ]
          },
          {
              partContent:[{
               onep:'法律课',
                twop:''
            },
            {
            onep:'心理学',
            twop:''
          },
           {
            onep:'社会学',
            twop:''
          }
            ]
          }
        ],
        ablityCore:[
          {
            title:"仪容仪表",
            content:[]
          },
          {
            title:"沟通",
            content:["寒暄","介绍","观察",  "倾听","询问","同理"]
          },
          {
            title:"处置",
            content:["原则","预案","隔离","调解","上报"]
          },{
            title:"职业规范",
            content:[]
          }
        ],
       wangge:["信息收集员","便民服务员","纠纷调解员","隐患排查员","治安巡逻员","爱心帮扶员","政策宣讲员","心理疏导员"],
       wangge2:["任务","目标","目标","规范","工具","话素","难点","范例","涉及法律心理知识点"],
        indMediations:[
          {
            matters:["离婚调解","财产纠纷","赡养老人","家庭暴力"],
            matterType:"家事调解"
          },
           {
            matters:["知识产权","公司纠纷","海事纠纷","金融纠纷"],
            matterType:"商事调解"
          },
           {
            matters:["信用卡","网购、P2P","股票、购车","购房"],
            matterType:"新型商事调解"
          },
          {
            matters:["导游购物","旅游事故","交通食宿","涉外旅游"],
            matterType:"旅游调解"
          },
           {
            matters:["误解性纠纷","医疗事故","用药用具","院内感染"],
            matterType:"医疗调解"
          },
           {
            matters:["物业调解","劳动调解","交通调解"],
            matterType:"其他调解"
          }
        ],
        abilitys:["知识调解","1接","2理","3创","4促","5跟","综合演练"],
        keti:[
            {
                url:require("@/assets/person/Picture1.png"),
            },
              {
                url:require("@/assets/person/Picture2.png"),
            },
              {
                url:require("@/assets/person/Picture3.png"),
            },
              {
                url:require("@/assets/person/Picture4.png"),
            },
              {
                url:require("@/assets/person/Picture5.png"),
            },
              {
                url:require("@/assets/person/Picture6.png"),
            }
        ],
        huobans:[{
            imgurl:require("@/assets/person/hezuo1.png"),
        },
        {
            imgurl:require("@/assets/person/hezuo2.png"),
        },
        {
            imgurl:require("@/assets/person/hezuo3.png"),
        },
        {
            imgurl:require("@/assets/person/hezuo4.png"),
        },
        {
            imgurl:require("@/assets/person/hezuo5.png"),
        }],
          tab1: [
        {
          url: require("@/assets/person/zhibo1.png"),
          text: "北京市委预付式消费信用监管和服务工作专题专题调研会"
        },
        {
          url: require("@/assets/person/zhibo2.png"),
          text: "全国政法智能化建设技术装备及成果展"
        },
        {
          url: require("@/assets/person/zhibo3.png"),
          text: "商务部市场体系建设司来我司调研"
        },
         {
          url: require("@/assets/person/zhibo4.png"),
          text: "商务部市场体系建设司来我司调研"
        }
      ],
        tab2: [
        {
          url: require("@/assets/person/ms.png"),
          text: "北京市委预付式消费信用监管和服务工作专题专题调研会"
        },
        {
          url: require("@/assets/person/ms2.png"),
          text: "全国政法智能化建设技术装备及成果展"
        },
        {
          url: require("@/assets/person/ms3.png"),
          text: "商务部市场体系建设司来我司调研"
        },
        {
          url: require("@/assets/person/ms4.png"),
          text: "商务部市场体系建设司来我司调研"
        },
        {
          url: require("@/assets/person/ms5.png"),
          text: "商务部市场体系建设司来我司调研"
        }
      ],
             tab3: [
        {
          url: require("@/assets/person/dz1.png"),
          text: "北京市委预付式消费信用监管和服务工作专题专题调研会"
        },
        {
          url: require("@/assets/person/dz2.png"),
          text: "全国政法智能化建设技术装备及成果展"
        },
        {
          url: require("@/assets/person/dz3.png"),
          text: "商务部市场体系建设司来我司调研"
        },
        {
          url: require("@/assets/person/dz4.png"),
          text: "商务部市场体系建设司来我司调研"
        }
      ],
           swiperOptions: {
                slidesOffsetBefore: 10,
                slidesOffsetAfter: 10,
                slidesPerView: '3', // 可见数量
                freeMode: true,
                spaceBetween: 15,
                history: 'love',
                pagination :{
	               el: '.swiper-pagination',
	                clickable :true,
                },
                // 设置点击箭头
                navigation: {
                    nextEl: '.next',
                    prevEl: '.prev',
                },
                autoplay:{
                  delay:3500,
                  autoplayDisableOnInteraction:false
                },
                centeredSlides: true,
                loop: true,
                  
        },
      }
  },
  methods:{
handleSelect(key, keyPath){
    this.activeIndex=key;
    // console.log("ssssssss",key, keyPath,this.activeIndex)
}
  }
}
</script>

<style lang="scss" scoped>
.page-box{
    width:100%;
    background: #F8FBFF;
    padding-bottom:0.60rem;
}
.person-Train{
    background-image: url("../../assets/person/pbg.png");
    background-size:100% 100%;
 .train-dec{
    margin:0 auto;
    width: 11.71rem;
    height: 0.77rem;
    font-family: PingFangSC-Light;
    font-size: 0.18rem;
    color: #1C1C1C;
    text-align: center;
    line-height: 0.36rem;
    padding-bottom:1.09rem;
}
}
.com-bg{
    margin:0 auto;
    width:14.32rem;
    .section{
        text-align: center;
        background: #fff;
        .swiper-pagination{
            left:50%;
            transform: translate(-50%);
           bottom: -0.15rem;
            // padding:0.12rem 0;
        }
        /deep/ .swiper-pagination-bullet{
            width: 0.20rem;
            height: 0.03rem;
            background: #BFC7D1;
            border-radius: 0;
            margin-right:0.12rem;
        }
        /deep/.swiper-pagination-bullet-active{
            background:#136EFF;
        }
        /deep/.el-menu.el-menu--horizontal{
            border-bottom:none;
            padding-bottom:0.17rem;
            .el-menu-item.is-active{
                color:#136EFF;
                border-bottom: 0.04rem solid #136EFF;
            }
        }
            .el-menu-demo{
            display: flex;
            justify-content: center;
            .el-menu-item{
              font-size: 0.18rem !important;
                color:#1C1C1C;
                margin:0.12rem 1.20rem;
                padding:0px;
            }
        }
    }
    .part-2{
          font-size:0.14rem;
        }
          .content-box{
            // margin:0 0.3rem;
            .content-box-2{
              margin:0.32rem 0;
            }
            .content-box-3{
              display: flex;
              justify-content: space-between;
          // padding: 0.32rem 0.12rem;
              .content-box-3-item{
                width:2.7rem;
                font-size:20px;
                padding:0.2rem 0.5rem 0 0.5rem;
                .item-title{
                  font-weight: 500;
                  font-size: 16px;
                  color: #1C1C1C;
                }
                .item-text{ 
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  align-items: center;
                  margin-top:0.24rem;
                  p{
                     margin-bottom:0.2rem;
                  }
                  
                   p:last-child{
                    margin-right:0;
                  }
                }
                
              }
              }
              .content-box-3-spe{
                align-items: center;
                margin:0.32rem 0.12rem;
              }
            }
            }
.htitle{
        width:14.32rem;
        font-size: 0.24rem;
        color: #1C1C1C;
        padding:0.40rem 0;
}
.htitle-p{
    font-size: 0.24rem;
    color: #1C1C1C;
    padding:0.40rem 0 0 0;
 }
 .htitle-spe{
   padding-top:0;
 }
.img-box{
    // margin:0 30rem 40rem 30rem;
    img{
        width:100%;
    }
}
.partners{
}
.backgroundUrl{
    background-image:url("../../assets/person/p-banner.png");
    background-size:100% 100%;
}
.dev-img{
    img{
        width:3.50rem;
        height:1.97rem;
    }
}

.content-title{
    height: 0.5rem;
    line-height: 0.5rem;
    background: #8024FF;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 0.2rem;
    color: #FFFFFF;
  }
  .content-title-bg{
    background:#FF9F24
  }
  .content-l-r-box{
    width:7.12rem;
    background: #F4F1FF;
    
    .neirong-box{
      display: flex;
      justify-content: space-between;
      flex:1;
      background: #F4F1FF;
      padding:0.32rem 0.12rem;
      .neirong{
        flex:1;
        height:1.39rem;
        background: #fff;
        margin-right:0.12rem;
        padding:0.43rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        span{
          font-size:0.2rem;
        }
        .neirong-p{
        font-size: 0.14rem;
        line-height:0.24rem;
        color: #1C1C1C;
        }
      }
      .neirong:last-child{
        margin-right:0;
      }
      .course{
        display: flex;
        
        padding:0 0.6rem;
        font-size:20px;
      }
    }
    .neirong-box-bg{
      background: #FFF6F1;
      .neirong-box-1{
        height:1.6rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        background: #fff;
        padding:0.4rem 0.6rem;
        li{
          list-style-type:none;
          font-size:0.14rem;
          line-height:52px;
          margin-right:0.63rem;
          &:nth-child(4n){
           margin-right:0;}
        }

      }
       .neirong-box-2{
         height:1.6rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: #fff;
        padding:0.4rem 0.6rem;
        li{
          width:0.48rem;
          list-style-type:none;
          font-size:0.14rem;
          line-height:52px;
          margin-right:0.6rem;
          &:nth-child(5n){
           margin-right:0;
           }
           &:last-child{
             flex:1;
            margin-right:0;
           }
        }
      }
    }
    .no-p{
      padding-right:0;
    }
    .neirong-r{
      margin-right:0.12rem;
      width:2.7rem;
    }
    .neirong-r:last-child{
      margin-right:0;
    }
  }
  .content-l-r-box-size{
    width:50%;
  }
  .content-items{
    display: flex;
    justify-content: space-around;
    background: #F4F1FF;
    padding:0.32rem 0.1rem;
    .core-ke{
      width: 1.88rem;
      height: 0.91rem;
      background: #FFFFFF;
      line-height: 0.91rem;
      font-size: 0.14rem;
      color: #1C1C1C;
      }
    .content-item{
      width:2rem;
      height: 2.5rem;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .content-item-info{
        padding: 0.24rem 0;
        p{
          font-size:0.14rem;
          height: 0.2rem;
          line-height: 0.2rem;
          color: #1C1C1C;
          margin-bottom: 0.23rem;
        }
        p:last-child{
        margin-bottom:0;
        }
      }
      .ke-name{
        height: 0.36rem;
        background: #8024FF;
        line-height: 0.36rem;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        }
    }
  }
  .content-items-bg{
    background: #FFF6F1;
  }
  .core-bg{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFF6F1;
    padding:0.32rem 0.12rem;
    box-sizing: border-box;
    .core-item{
      width:3.3rem;
      height:2.01rem;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top:0.2rem;
      box-sizing: border-box;
    }
    .core-item-spe{
      line-height:1.6rem;
    }
    .core-title{
      font-size:0.16rem;
      height: 16px;
      font-family: PingFangSC-Medium;
      font-weight: 600;
      color: #1C1C1C;
      letter-spacing: 0;
      // line-height: 16px;
    }
    .core-info{
      font-size:0.14rem;
      width:2.10rem;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      padding:0.24rem 0 0.4rem 0;
      justify-content: flex-start;
      div{
        margin-right:0.56rem;
        line-height:40px;
      }
      div:nth-child(3n){
        margin-right:0;
      }
      div:nth-child(5){
        // flex:1;
        // text-align:center;
      }
    }
  }
</style>
